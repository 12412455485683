<template>
    <div class="relative">
        <select :disabled="disabled" v-if="! searchable" v-bind="$attrs" @change="handleChange" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-hidden focus:ring-blue-500 focus:border-blue-500 rounded-md disabled:opacity-50" :class="[{'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': error}, ...customClass]">
            <slot />
        </select>

        <template v-else>
            <button
                :disabled="disabled"
                ref="button"
                @click="onButtonClick()"
                v-click-outside="onClickOutside"
                type="button"
                class="bg-white relative w-full border border-gray-300 rounded-md pl-3 pr-10 py-2 text-base text-left cursor-default focus:outline-hidden focus:ring-blue-500 focus:border-blue-500"
                aria-haspopup="listbox"
                :aria-expanded="open"
                aria-labelledby="listbox-label"
                :class="[{'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': error}, ...customClass]"
            >
                <span v-if="selected" class="block truncate">
                    {{ selected.name }}
                </span>
                <span v-else class="block truncate">
                    {{ $attrs.hasOwnProperty('placeholder') ? $attrs.placeholder : 'Select a item' }}
                </span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </span>
            </button>

            <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <div
                    v-show="open"
                    @keyup.enter.stop.prevent="onOptionSelect()"
                    @keyup.space.stop.prevent="onOptionSelect()"
                    @keyup.esc="close()"
                    @keyup.arrow-up.prevent="onArrowUp()"
                    @keyup.arrow-down.prevent="onArrowDown()"
                    ref="dropdown"
                    class="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black/5 divide-y divide-gray-200 focus:outline-hidden sm:text-sm"
                    v-cloak
                >
                    <input-search
                        :disabled="disabled"
                        id="searchbox"
                        placeholder="Filter..."
                        v-model="search"
                        class="w-full py-2 px-3"
                    />

                    <ul
                        ref="listbox"
                        tabindex="-1"
                        role="listbox"
                        aria-labelledby="listbox-label"
                        class="max-h-60 overflow-auto"
                    >
                        <template v-if="items.length">
                            <li
                                v-for="(item, index) in items"
                                :key="index"
                                @click="choose(index)"
                                @mouseenter="onMouseEnter(index)"
                                @mouseleave="onMouseLeave()"
                                class="cursor-default select-none relative py-2 pl-3 pr-9 text-gray-900"
                                :id="`listbox-option-${index}`"
                                role="option"
                                :class="{ 'text-white bg-blue-600': activeIndex === index, 'text-gray-900': activeIndex !== index }"
                            >
                                <span class="font-normal block truncate" :class="{ 'font-semibold': selectedIndex === index, 'font-normal': selectedIndex !== index }">
                                    {{ item.name }}
                                </span>

                                <span class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600" :class="{ 'text-white': activeIndex === index, 'text-blue-600': activeIndex !== index }" v-show="selectedIndex === index" v-cloak>
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                            </li>
                        </template>
                        <li v-else class="cursor-default pointer-events-none select-none text-center relative py-2 pl-3 pr-9 text-gray-500">
                            No items found
                        </li>
                    </ul>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
import InputSearch from './InputSearch.vue';

export default {
    name: 'BaseSelect',

    inheritAttrs: false,

    props: {
        disabled: {default:false, type:Boolean},
        error: {default:false, type:Boolean},
        searchable: {default:false, type:Boolean},
        customClass: {
            type: Array,
            default(){
                return [];
            },
        },
    },

    components: {
        InputSearch,
    },

    data: () => ({
        search: null,
        selected: null,
        open: false,
        selectedIndex: 0,
        activeIndex: 0,
        items: [],
        itemsBeforeSearch: [],
    }),

    mounted(){
        if (this.searchable) {
            this.initSearchbox();
        }
    },

    watch: {
        search(){
            if (this.itemsBeforeSearch.length === 0) {
                this.itemsBeforeSearch = this.items;
            }

            this.filterByName();
        },
    },

    methods:{
        handleChange({target}){
            this.$emit('input', target.value);
        },

        initSearchbox(){
            this.setItems();
            this.setSelected();
        },

        onButtonClick(){
            this.open = ! this.open;

            if (!this.open) {
                return;
            }

            setTimeout(function(){
                const search = document.getElementById('search');

                search.value = '';
                search.focus();
            }, 100);

            if (! this.selected) {
                return;
            }

            this.selectedIndex = this.items.findIndex((item) => item.value === this.selected.value);
            this.activeIndex = this.selectedIndex;

            setTimeout(() => {
                this.scrollIntoView(this.selectedIndex);
            }, 100);
        },

        onClickOutside({target}){
            if (['searchbox', 'search'].includes(target.id)) {
                return;
            }

            this.close();
        },

        close(){
            if (! this.open) {
                return;
            }

            this.open = false;
            this.search = null;
            this.reset();
        },

        reset(){
            if (this.itemsBeforeSearch.length === 0) {
                return;
            }

            this.items = this.itemsBeforeSearch;
            this.itemsBeforeSearch = [];
        },

        onOptionSelect(){
            if(this.items.length === 0){
                return;
            }

            if (! this.items[this.activeIndex]) {
                this.activeIndex = 0;
            }

            this.selected = this.items[this.activeIndex];
            this.selectedIndex = this.activeIndex;

            this.$emit('input', this.selected.value);
            this.close();
        },

        choose(index){
            this.activeIndex = this.items[index] ? index : 0;
            this.selected = this.items[this.activeIndex];
            this.selectedIndex = this.activeIndex;

            this.$emit('input', this.selected.value);
            this.close();
        },

        onArrowUp(){
            this.activeIndex = this.items[this.activeIndex]
                ? this.activeIndex - 1
                : [...this.items.keys()].pop();

            this.scrollIntoView(this.activeIndex);
        },

        onArrowDown(){
            this.activeIndex = this.items[this.activeIndex]
                ? this.activeIndex + 1
                : 0;

            const element = document.getElementById(`listbox-option-${this.activeIndex}`);
            const list = this.$refs.listbox;
            const searchboxHeight = document.getElementById('searchbox').clientHeight + 8;

            if ((element.offsetTop - searchboxHeight - list.scrollTop) >= (list.clientHeight - element.scrollHeight)) {
                list.scrollTo(0, element.offsetTop - searchboxHeight);
            }
        },

        onMouseEnter(index){
            this.activeIndex = index;
            this.$refs.dropdown.focus();
        },

        onMouseLeave(){
            if (! this.open) {
                return;
            }

            this.activeIndex = 0;
        },

        scrollIntoView(index){
            const searchboxHeight = document.getElementById('searchbox').clientHeight + 8;

            this.$refs.listbox.scrollTop = document.getElementById(`listbox-option-${index}`).offsetTop - searchboxHeight;
        },

        filterByName(){
            if (this.search === '' || ! this.search) {
                this.reset();

                return;
            }

            this.items = this.itemsBeforeSearch.filter((item) => this.matchName(item.name.toLowerCase()));
        },

        matchName(name){
            const data = this.search.toLowerCase();

            if (name.includes(data) || name === data || name.split(' ').includes(data)) {
                return true;
            }

            return false;
        },

        setItems(){
            if (! this.$slots.default) {
                return;
            }

            this.$slots.default.forEach(({children, data:{domProps: {value}}}) => this.items.push({
                name: children[0].text.trim(),
                value,
            }));
        },

        setSelected(){
            if(this.items.length === 0 || ! Object.prototype.hasOwnProperty.call(this.$attrs, 'value')){
                return;
            }

            this.selectedIndex = this.items.findIndex((element) => {
                return element.value === this.$attrs.value;
            });

            this.activeIndex = this.selectedIndex;
            this.selected = this.items[this.selectedIndex];
        },
    },
};
</script>
