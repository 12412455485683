<template>
    <div>
        <label for="search" class="sr-only">{{ placeholder }}</label>
        <div class="relative rounded-full text-gray-400 focus-within:text-gray-600">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </div>
            <input
                @input="handleInput"
                @change="handleChange"
                @keypress.enter="$emit('keypress-enter')"
                id="search"
                type="search"
                v-bind="$attrs"
                class="appearance-none block w-full px-4 py-2 border border-gray-200 rounded-md placeholder-gray-400 focus:outline-hidden focus:ring-blue-500 focus:border-blue-500 sm:text-sm pl-9"
                :placeholder="placeholder"
                :disabled="loading"
                :class="{'cursor-not-allowed': loading}"
                autocomplete="off"
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputSearch',
    inheritAttrs: false,
    props: {
        loading: {default:false, type:Boolean},
        placeholder: {required:true, type:String},
    },
    methods:{
        handleInput({target}){
            this.$emit('input', target.value);
        },
        handleChange({target}){
            this.$emit('change', target.value);
        }
    },
};
</script>
